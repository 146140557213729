<template>
  <div>
    <v-card flat>
      <v-card-title>
        <PageTitle
            class="w-100"
            icon="mdi-chart-bar"
            title="آمار آگهی ها"
        />
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col :cols="3">
            <v-combobox
                outlined
                label="انتخاب منظقه"
                :items="locations"
                item-value="id"
                item-text="name"
                v-model="filters.areaid"
            />
          </v-col>

          <v-col :cols="3">
            <v-text-field
                v-model="filters.startday"
                hide-details
                outlined
                label="از تاریخ"
                class="start-date-picker"
            />
            <date-picker
                auto-submit
                v-model="filters.startday"
                format="jYYYY/jMM/jDD"
                custom-input=".start-date-picker"
            />

          </v-col>

          <v-col :cols="3">
            <v-text-field
                v-model="filters.endday"
                hide-details
                outlined
                label="تا تاریخ"
                class="end-date-picker"
            />
            <date-picker
                auto-submit
                v-model="filters.endday"
                format="jYYYY/jMM/jDD"
                custom-input=".end-date-picker"
            />

          </v-col>

          <v-col :cols="3">
            <v-btn
                elevation="0"
                :disabled="!(filters.areaid && filters.startday && filters.endday)"
                :loading="filters.loading"
                @click="fetchData"
                block color="primary" x-large>
              نمایش آمار
            </v-btn>
          </v-col>

          <v-col :cols="12">
            <div id="chart"></div>
          </v-col>


        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle"
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'
import {fileStatistics} from "../../api";
import Highcharts from "highcharts"
import moment from "jalali-moment";

export default {
  name: "Statistics",
  components: {
    PageTitle,
    datePicker: VuePersianDatetimePicker
  },
  data() {
    return {
      locations: [],
      filters: {
        loading: false,
        areaid: null,
        startday: moment().locale('fa').format('jYYYY/jMM/jDD'),
        endday:  moment().locale('fa').format('jYYYY/jMM/jDD'),
        take: 1000,
      },
    }
  },
  mounted() {
    for (let i = 1; i <= 22; i++) {
      this.locations.push({
        id: i,
        name: 'منطقه ' + i
      })
    }
  },

  methods: {
    async fetchData() {
      this.filters.loading = true;
      try {
        const res = (await fileStatistics({
          ...this.filters,
          areaid: this.filters.areaid?.id
        }))?.data?.message || [];
        const xAxis = res.map(item => item.neighborhoodName)
        const yAxis = res.map(item => item.recordcount)
        Highcharts.chart('chart', {
          chart: {
            type: 'column'
          },
          title: {
            text: 'آمار فایل های منطقه ' + this.filters.areaid?.id,
            style: {
              color: '#000',
              fontFamily: '"iranyekan"',
              fontSize: '16px',
              fontWeight: 'bold'
            },
            useHTML: true
          },
          xAxis: {
            labels: {
              style: {
                color: '#000',
                fontFamily: '"iranyekan"',
                fontSize: '14px',
                fontWeight: 'bold'
              }
            },
            categories: xAxis,
            crosshair: false
          },
          yAxis: {
            min: 0,
            title: {
              style: {
                color: '#000',
                fontFamily: '"iranyekan"',
                fontSize: '14px',
                fontWeight: 'bold'
              },
              text: 'تعداد فایل',
              useHTML: true
            },
          },
          series: [{
            type: 'column',
            colorByPoint: false,
            showInLegend: true,
            name: "",
            dataLabels: {
              enabled: true
            },
            data: yAxis
          }],
          plotOptions: {
            column: {
              pointPadding: 0.2,
              borderWidth: 0
            }
          },
          labels: {
            skew3d: true,
            style: {
              fontSize: '16px',
              fontFamily: '"iranyekan"'
            }
          },

        });
      } catch (e) {
        console.log(e)
      }
      this.filters.loading = false;
    }
  }
}
</script>

<style scoped>

</style>